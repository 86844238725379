import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { associateBaseUrl, baseUrl } from "../../config/config";
import {
  delete_associate_api,
  associates_list_api,
  generate_associate_token_api,
} from "../../DAL/Associates/Associates";
import ComponentPopup from "../../components/GeneralComponents/ComponentPopup";
import ChangePassword from "../../components/Associates/ChangePassword";
import AddOrUpdateAssociate from "../../components/Associates/AddOrUpdateAssociate";
import { useContentSetting } from "../../Hooks/ContentSetting";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function AssociatesList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [associatesList, setAssociatesList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [openPassword, setOpenPassword] = useState(false);
  const { userInfo } = useContentSetting();
  const handleOpenPasswordPopup = (data) => {
    setSelectedObject(data);
    setOpenPassword(true);
  };

  const handleClosePasswordPopup = () => {
    setOpenPassword(false);
    setSelectedObject({});
  };

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedObject({});
    setFormType("ADD");
  };

  const get_associates_list = async () => {
    const result = await associates_list_api(userInfo.role);
    if (result.code === 200) {
      const data = result.associates_list.map((associate) => {
        return {
          ...associate,
          table_avatar: {
            src: baseUrl + associate.profile_image,
            alt: associate.name,
          },
          admin_name: associate.admin_user?.name,
          created_at: moment(associate.createdAt).format("DD-MM-YYYY hh:mm A"),
        };
      });
      setAssociatesList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
      className: "reduce-cell-padding",
    },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone" },
    { id: "status", label: "Status", type: "row_status" },
    { id: "created_at", label: "Created At" },
  ];

  if (userInfo.role === "supper_admin") {
    TABLE_HEAD.splice(4, 0, {
      id: "admin_name",
      label: "Admin Name",
    });
  }
  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };

  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const generate_token = async (row) => {
    const result = await generate_associate_token_api(row._id);
    if (result.code === 200) {
      window.open(`${associateBaseUrl}login-by-admin/${result.token}`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handlePersonalNotes = (data) => {
    navigate(`/associates/personal-notes/${data._id}`);
  };

  const handleToDoList = (data) => {
    navigate(`/associates/to-do-lists/${data._id}`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Login As Associate",
      icon: "majesticons:login-line",
      handleClick: generate_token,
    },
    {
      label: "Change Password",
      icon: "material-symbols:key-outline",
      handleClick: handleOpenPasswordPopup,
    },
    {
      label: "Personal Notes",
      icon: "codicon:note",
      handleClick: handlePersonalNotes,
    },
    {
      label: "To Do List",
      icon: "ic:baseline-list",
      handleClick: handleToDoList,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_associate_api(selectedObject._id);
    if (result.code === 200) {
      const data = associatesList.filter(
        (associate) => associate._id !== selectedObject._id
      );
      setAssociatesList(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_associates_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Associates List</h2>
                </div>
                <div className="add--button mb-3">
                  <button onClick={handleOpenDrawer}>Add Associate</button>
                </div>
              </div>
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={associatesList}
                MENU_OPTIONS={MENU_OPTIONS}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType === "ADD" ? "Add" : "Update"} Associate`}
        componentToPassDown={
          <AddOrUpdateAssociate
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            associatesList={associatesList}
            setAssociatesList={setAssociatesList}
            selectedObject={selectedObject}
          />
        }
      />

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this Associate?"}
        handleAgree={handleDelete}
      />

      <ComponentPopup
        openPopup={openPassword}
        setOpenPopup={setOpenPassword}
        title="Change Password"
        componentToPassDown={
          <ChangePassword
            handleClose={handleClosePasswordPopup}
            selectedObject={selectedObject}
          />
        }
      />
    </>
  );
}
