import React, { useEffect, useState } from "react";
import peer from "../service/peer";
import { useContentSetting } from "../Hooks/ContentSetting";
import ReactPlayer from "react-player";

export default function NewCallPage() {
  const { socket, userInfo } = useContentSetting();
  const [myStream, setMyStream] = useState();
  const [remoteStream, setRemoteStream] = useState();
  const peerConnections = {};
  const roomID = "123";

  const startCall = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    console.log(stream, "streamstreamstream");
    setMyStream(stream);
    const offer = await peer.getOffer();

    await socket.emit("send:offer", {
      to: "651446e8b73bca990adde64a",
      from: userInfo._id,
      offer,
    });
  };

  const sendStreams = () => {
    for (const track of myStream.getTracks()) {
      peer.peer.addTrack(track, myStream);
    }
  };

  const handleNegoNeeded = async () => {
    console.log("handleNegoNeeded S");
    const offer = await peer.getOffer();
    socket.emit("peer:nego:needed", {
      offer,
      from: userInfo._id,
      to: "651446e8b73bca990adde64a",
    });
  };

  const handleIceNeeded = async () => {
    const offer = await peer.getOffer();
    console.log(offer, "handleIceNeeded");
    socket.emit("ice:gather:needed", {
      offer,
      from: userInfo._id,
      to: "651446e8b73bca990adde64a",
    });
  };

  useEffect(() => {
    peer.peer.addEventListener("negotiationneeded", handleNegoNeeded);
    return () => {
      peer.peer.removeEventListener("negotiationneeded", handleNegoNeeded);
    };
  }, []);

  useEffect(() => {
    peer.peer.addEventListener("icegatheringstatechange", handleIceNeeded);
    return () => {
      peer.peer.removeEventListener("icegatheringstatechange", handleIceNeeded);
    };
  }, []);

  const handleCallAccepted = ({ from, ans }) => {
    peer.setLocalDescription(ans);
    console.log("Call Accepted!");
    sendStreams();
  };

  useEffect(() => {
    peer.peer.addEventListener("track", async (ev) => {
      const remoteStream = ev.streams;
      console.log("GOT TRACKS!!");
      setRemoteStream(remoteStream[0]);
    });
  }, []);

  const handleNegoNeedIncomming = async ({ from, offer }) => {
    console.log("handleNegoNeedIncomming A");
    const ans = await peer.getAnswer(offer);
    socket.emit("peer:nego:done", { to: "651446e8b73bca990adde64a", ans });
  };

  const handleIceIncomming = async ({ from, offer }) => {
    console.log("handleNegoNeedIncomming A");
    const ans = await peer.getAnswer(offer);
    socket.emit("ice:gather:done", { to: "651446e8b73bca990adde64a", ans });
  };

  const handleIceFinal = async ({ ans }) => {
    console.log("handleNegoNeedFinal A");
    await peer.setLocalDescription(ans);
  };

  const handleNegoNeedFinal = async ({ ans }) => {
    console.log("handleNegoNeedFinal A");
    await peer.setLocalDescription(ans);
  };

  useEffect(() => {
    socket.on("call:accepted", handleCallAccepted);
    socket.on("peer:nego:needed", handleNegoNeedIncomming);
    socket.on("peer:nego:final", handleNegoNeedFinal);

    socket.on("ice:gather:needed", handleIceIncomming);
    socket.off("ice:gather:final", handleIceFinal);

    return () => {
      socket.off("call:accepted", handleCallAccepted);
      socket.off("peer:nego:needed", handleNegoNeedIncomming);
      socket.off("peer:nego:final", handleNegoNeedFinal);

      socket.off("ice:gather:needed", handleIceIncomming);
      socket.off("ice:gather:final", handleIceFinal);
    };
  }, [socket, myStream]);

  return (
    <div>
      NewCallPage
      <button onClick={startCall}>Call</button>
      {myStream && (
        <>
          <h1>My Stream</h1>
          <ReactPlayer
            playing
            muted
            height="100px"
            width="200px"
            url={myStream}
          />
          <button onClick={sendStreams}>send Streams</button>
        </>
      )}
      {remoteStream && (
        <>
          <h1>My Stream</h1>
          <ReactPlayer
            playing
            muted
            height="100px"
            width="200px"
            url={remoteStream}
          />
        </>
      )}
    </div>
  );
}
