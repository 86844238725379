import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import {
  AnnouncementDetail,
  AnnouncementsList,
  AnnouncementsTemplates,
  AnnouncementsUsers,
  AssociatesList,
  Chatting,
  CourseList,
  Dashboard,
  HolyQuranIndexes,
  HolyQuranIndexMainPage,
  HolyQuranIndexPages,
  ImageSlider,
  IslamicPillar,
  LessonBooks,
  Login,
  PersonalNotes,
  PersonalNotesByUser,
  PrayerLesson,
  QuidaEnglishIndexMainPage,
  QuidaEnglishIndexPages,
  StudentRecentReports,
  StudentReports,
  StudentsGroups,
  StudentsList,
  StudentsListByTeacher,
  TeachersList,
  ToDoLists,
  ToDoListsByUser,
  TrainingLesson,
  TransactionsList,
  UsersList,
  WebPages,
  WebsiteContent,
} from "./pages";
import LobbyScreen from "./pages/Looby";
import RoomPage from "./pages/Caller";
import Caller2 from "./pages/Caller2";
import NewCallPage from "./pages/NewCallPage";
import Complaints from "./pages/Complaints";
import FeedbackMechanism from "./pages/FeedbackMechanism";
import Challenges from "./pages/Challenges";
import ClassTimings from "./pages/ClassTimings";
import PublicHolidays from "./pages/PublicHolidays";

export default function Router() {
  const Authentication = () => {
    if (localStorage.getItem("token")) {
      return <Navigate to="/dashboard"> </Navigate>;
    }
    if (!localStorage.getItem("token")) {
      return <Navigate to="/login"> </Navigate>;
    }
  };

  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/NewCallPage" element={<NewCallPage />} />
        <Route path="/LobbyScreen" element={<LobbyScreen />} />
        <Route path="/room/:roomId" element={<RoomPage />} />
        <Route path="/Caller2" element={<Caller2 />} />
        <Route path="/admin-users" element={<UsersList />} />
        <Route path="/lesson-books" element={<LessonBooks />} />
        <Route path="/lesson-books/quran-pak" element={<HolyQuranIndexes />} />
        <Route
          path="/lesson-books/quran-pak/:index"
          element={<HolyQuranIndexPages />}
        />
        <Route
          path="/lesson-books/quran-pak/:index/:page_index"
          element={<HolyQuranIndexMainPage />}
        />
        <Route
          path="/lesson-books/quida-english"
          element={<QuidaEnglishIndexPages />}
        />
        <Route
          path="/lesson-books/quida-english/:page_index"
          element={<QuidaEnglishIndexMainPage />}
        />
        <Route path="/students" element={<StudentsList />} />
        <Route
          path="/students/personal-notes/:user_id"
          element={<PersonalNotesByUser user_type="student" />}
        />
        <Route
          path="/students/to-do-lists/:user_id"
          element={<ToDoListsByUser user_type="student" />}
        />
        <Route path="/students-groups" element={<StudentsGroups />} />
        <Route path="/training-lesson" element={<TrainingLesson />} />
        <Route path="/teachers" element={<TeachersList />} />
        <Route
          path="/teachers/personal-notes/:user_id"
          element={<PersonalNotesByUser user_type="teacher" />}
        />
        <Route
          path="/teachers/students-list/:teacher_id"
          element={<StudentsListByTeacher />}
        />
        <Route
          path="/teachers/to-do-lists/:user_id"
          element={<ToDoListsByUser user_type="teacher" />}
        />
        <Route path="/associates" element={<AssociatesList />} />
        <Route
          path="/associates/personal-notes/:user_id"
          element={<PersonalNotesByUser user_type="associate" />}
        />
        <Route
          path="/associates/to-do-lists/:user_id"
          element={<ToDoListsByUser user_type="associate" />}
        />
        <Route path="/prayer-lessons" element={<PrayerLesson />} />
        <Route path="/class-room" element={<Chatting />} />
        <Route path="/class-room/:conversation_id" element={<Chatting />} />

        <Route path="/personal-notes" element={<PersonalNotes />} />
        <Route path="/to-do-lists" element={<ToDoLists />} />

        <Route path="/transactions" element={<TransactionsList />} />
        <Route path="/students-reports" element={<StudentReports />} />
        <Route path="/recent-reports" element={<StudentRecentReports />} />
        <Route path="/announcements" element={<AnnouncementsList />} />
        <Route
          path="/announcements/detail/:announcement_id"
          element={<AnnouncementDetail />}
        />
        <Route
          path="/announcement-templates/detail/:announcement_id"
          element={<AnnouncementDetail />}
        />
        <Route
          path="/announcements/users/:announcement_id"
          element={<AnnouncementsUsers />}
        />
        <Route
          path="/announcement-templates"
          element={<AnnouncementsTemplates />}
        />
        <Route path="/web-pages" element={<WebPages is_template={false} />} />
        <Route
          path="/template-pages"
          element={<WebPages is_template={true} />}
        />
        <Route
          path="/web-pages/image-slider/:page_slug"
          element={<ImageSlider />}
        />
        <Route
          path="/template-pages/image-slider/:page_slug"
          element={<ImageSlider />}
        />
        <Route
          path="/web-pages/islamic-pillars/:page_slug"
          element={<IslamicPillar />}
        />
        <Route
          path="/template-pages/islamic-pillars/:page_slug"
          element={<IslamicPillar />}
        />
        <Route path="/web-pages/courses/:page_slug" element={<CourseList />} />
        <Route
          path="/template-pages/courses/:page_slug"
          element={<CourseList />}
        />
        <Route path="/website-content" element={<WebsiteContent />} />
        <Route path="/complaints" element={<Complaints />} />
        <Route path="/FeedbackMechanism" element={<FeedbackMechanism />} />
        <Route path="/Challenges" element={<Challenges />} />
        <Route path="/ClassTimings" element={<ClassTimings />} />
        <Route path="/PublicHolidays" element={<PublicHolidays />} />
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/login" element={<Login />} />
      </Route>
    </Routes>
  );
}
