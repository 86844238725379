const get_icon = (name) => {
  return <i className={`nav-icon ${name}`}></i>;
};

const sidebarConfig = (userInfo) => {
  let sidebarMenus = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: get_icon("fas fa-tachometer-alt"),
    },
    {
      title: "Users",
      icon: get_icon("fas fa-users"),
      child_options: [
        {
          title: "Associates",
          path: "/associates",
          icon: get_icon("fas fa-users"),
        },
        {
          title: "Students",
          path: "/students",
          icon: get_icon("fas fa-user-graduate"),
        },
        {
          title: "Teachers",
          path: "/teachers",
          icon: get_icon("fas fa-chalkboard-teacher"),
        },
      ],
    },
    {
      title: "Web Pages",
      icon: get_icon("fa-regular fa-file-lines"),
      child_options: [
        {
          title: "Templates Pages",
          path: "/template-pages",
          icon: get_icon("fa-regular fa-file-lines"),
        },
        {
          title: "Pages",
          path: "/web-pages",
          icon: get_icon("fa-regular fa-file-lines"),
        },
      ],
    },
    {
      title: "Students Reports",
      icon: get_icon("fa-solid fa-list"),
      child_options: [
        {
          title: "Recent Reports",
          path: "/recent-reports",
          icon: get_icon("fa-solid fa-list"),
        },
        {
          title: "All Reports",
          path: "/students-reports",
          icon: get_icon("fa-solid fa-list"),
        },
      ],
    },
    {
      title: "Class Chat",
      path: "/class-room",
      icon: get_icon("fas fa-users"),
    },
    {
      title: "Students Groups",
      path: "/students-groups",
      icon: get_icon("fas fa-users"),
    },

    {
      title: "Reading Material",
      icon: get_icon("fas fa-book-reader"),
      child_options: [
        {
          title: "Lesson Books",
          path: "/lesson-books",
          icon: get_icon("fas fa-book-reader"),
        },
        {
          title: "Prayer Lessons",
          path: "/prayer-lessons",
          icon: get_icon("fas fa-book-reader"),
        },
        {
          title: "Training Lesson",
          path: "/training-lesson",
          icon: get_icon("fa-solid fa-globe"),
        },
      ],
    },
    {
      title: "Announcements",
      icon: get_icon("fas fa-bullhorn"),
      child_options: [
        {
          title: "Templates",
          path: "/announcement-templates",
          icon: get_icon("fas fa-bullhorn"),
        },
        {
          title: "Announcements",
          path: "/announcements",
          icon: get_icon("fas fa-bullhorn"),
        },
      ],
    },
    {
      title: "Extras",
      icon: get_icon("fa-regular fa-file-lines"),
      child_options: [
        {
          title: "Personal Notes",
          path: "/personal-notes",
          icon: get_icon("fa-solid fa-book"),
        },
        {
          title: "To Do Lists",
          path: "/to-do-lists",
          icon: get_icon("fa-solid fa-list"),
        },
      ],
    },
  ];

  // Correct the conditional check
  if (userInfo.role === "supper_admin") {
    sidebarMenus[1].child_options.push({
      title: "Users",
      path: "/admin-users",
      icon: get_icon("fas fa-user-friends"),
    });
  }

  return sidebarMenus;
};

export default sidebarConfig;
