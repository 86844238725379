import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import GeneralCkeditor from "../GeneralComponents/GeneralCkeditor";
import {
  add_announcements_api,
  update_announcements_api,
} from "../../DAL/Announcements/Announcements";
import MUIAutocomplete from "../GeneralComponents/MUIAutocomplete";
import { associate_announcement_templates_api } from "../../DAL/general_routes/general_routes";
import { show_proper_words } from "../../utils/constant";

export default function AddOrUpdateAnnouncement({
  onCloseDrawer,
  setAnnouncementsData,
  formType,
  selectedObject,
  announcementsData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [templatesData, setTemplatesData] = useState([]);
  const [associatesList, setAssociatesList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [state, setState] = useState({
    title: "",
    description: "",
    users_type: "all_associates",
    status: true,
    is_template: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formData = {
      status: state.status,
      title: state.title,
      description: state.description,
      is_template: state.is_template,
      users_type: state.users_type,
      users_list: selectedUsers,
    };

    const result =
      formType === "ADD"
        ? await add_announcements_api(formData)
        : await update_announcements_api(formData, state._id);
    if (result.code === 200) {
      let new_announcement = result.announcement;
      let users_type = show_proper_words(new_announcement.users_type);
      if (new_announcement.users_type == "associates") {
        users_type = `${users_type}(${new_announcement.users_list.length})`;
      }

      let announcement = {
        ...new_announcement,
        show_users_type: users_type,
      };
      if (formType === "ADD") {
        setAnnouncementsData((announcementsData) => [
          announcement,
          ...announcementsData,
        ]);
      } else {
        const newState = announcementsData.map((obj) => {
          if (obj._id === state._id) {
            return announcement;
          }
          return obj;
        });
        setAnnouncementsData(newState);
      }
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_associate_announcement_templates = async () => {
    const result = await associate_announcement_templates_api();
    if (result.code === 200) {
      setTemplatesData(result.announcements);
      setAssociatesList(result.associates);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeTemplate = (value) => {
    setState((prevState) => {
      return {
        ...prevState,
        title: value.title,
        description: value.description,
      };
    });
    setSelectedTemplate(value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (formType == "EDIT") {
      setState(selectedObject);
      setSelectedUsers(selectedObject.users_list);
    }
    get_associate_announcement_templates();
  }, [formType]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            {formType == "ADD" && (
              <div className="col-12 col-md-6 mt-4">
                <MUIAutocomplete
                  inputLabel="Template"
                  selectedOption={selectedTemplate}
                  setSelectedOption={handleChangeTemplate}
                  optionsList={templatesData}
                  autoComplete="new-password"
                  name="title"
                />
              </div>
            )}

            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="title"
                label="Title"
                variant="outlined"
                name="title"
                value={state.title}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="status">Status</InputLabel>
                <Select
                  labelId="status"
                  id="status"
                  label="Status"
                  name="status"
                  className="mui-select"
                  value={state.status}
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Users Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Users Type"
                  name="users_type"
                  className="mui-select"
                  value={state.users_type}
                  onChange={handleChange}
                >
                  <MenuItem value="all_associates">All Associates</MenuItem>
                  <MenuItem value="associates">Selected Associates</MenuItem>
                </Select>
              </FormControl>
            </div>
            {state.users_type == "associates" && (
              <div className={`col-12 mt-4`}>
                <MUIAutocomplete
                  inputLabel="Associates"
                  selectedOption={selectedUsers}
                  setSelectedOption={setSelectedUsers}
                  optionsList={associatesList}
                  autoComplete="new-password"
                  multiple={true}
                  required={true}
                  name="name"
                />
              </div>
            )}
            <div className="col-12 mt-3">
              <h6>Description *</h6>
              <GeneralCkeditor
                setInputs={setState}
                inputs={state}
                name="description"
                editorHeight={320}
              />
            </div>
            <div className="col-12 add--button text-end mt-3">
              <button disabled={isLoading}>
                {isLoading
                  ? formType === "ADD"
                    ? "Saving..."
                    : "Updating..."
                  : formType === "ADD"
                  ? "Save"
                  : "Update"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
