import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import CustomMUITable from "../../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { baseUrl } from "../../../config/config";
import ConfirmationPopup from "../../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../../components/GeneralComponents/CustomDrawer";
import AddOrUpdateImageSlider from "../../../components/WebPages/ImageSlider/AddOrUpdateImageSlider";
import {
  delete_image_slider_api,
  image_sliders_list_api,
} from "../../../DAL/WebPages/ImageSlider";
import { sidebar_pathname } from "../../../utils/constant";

export default function ImageSlider() {
  const { enqueueSnackbar } = useSnackbar();
  const { page_slug } = useParams();
  const { pathname } = useLocation();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [imageSlider, setImageSlider] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [pageInfo, setPageInfo] = useState({});

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedObject({});
    setFormType("ADD");
  };

  const get_image_sliders_list = async () => {
    const result = await image_sliders_list_api(page_slug);
    if (result.code === 200) {
      const data = result.image_slider.map((image_slider) => {
        return {
          ...image_slider,
          table_avatar: {
            src: baseUrl + image_slider.image,
            alt: image_slider.name,
          },
        };
      });
      setImageSlider(data);
      setPageInfo(result.web_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleOpenImage = (row, index) => {
    window.open(baseUrl + row.image);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
      className: "reduce-cell-padding cursor-pointer",
      handleClick: handleOpenImage,
    },
    { id: "title", label: "Title" },
    { id: "description", label: "Description" },
    { id: "status", label: "Status", type: "row_status" },
    { id: "action", label: "Action", type: "action" },
  ];

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };
  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_image_slider_api(selectedObject._id);
    if (result.code === 200) {
      const data = imageSlider.filter(
        (image_slider) => image_slider._id !== selectedObject._id
      );
      setImageSlider(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_image_sliders_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="back-button-title mb-3">
                <div class="back-button-box">
                  <Link to={sidebar_pathname(pathname)}>
                    <i className="fas fa-arrow-left"></i>
                  </Link>
                </div>
                <div className="table-title">{pageInfo.title}</div>
              </div>
              <div className="light-border-bottom d-flex justify-content-between">
                <div className="mb-2 mui-table-heading">
                  <h2>Image Slider</h2>
                </div>
                <div className="add--button mb-3">
                  <button onClick={handleOpenDrawer}>Add Image Slider</button>
                </div>
              </div>

              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={imageSlider}
                MENU_OPTIONS={MENU_OPTIONS}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType == "ADD" ? "Add" : "Update"} Image Slider`}
        componentToPassDown={
          <AddOrUpdateImageSlider
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            imageSlider={imageSlider}
            setImageSlider={setImageSlider}
            selectedObject={selectedObject}
          />
        }
      />

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this slider?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
