import React from "react";
import ParticipantView from "./ParticipantView";
import { useMeeting, useParticipant } from "@videosdk.live/react-sdk";
import { useState } from "react";
import { useEffect } from "react";
import { useClassRoom } from "../../../Hooks/ClassRoom";
import { Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { logo } from "../../../assets";
import { useContentSetting } from "../../../Hooks/ContentSetting";
import { message_by_meeting_api } from "../../../DAL/Messages/Messages";

export default function MeetingView(props) {
  const {
    autoJoin,
    isIncomingCall,
    isCallAccepted,
    setIsIncomingCall,
    callUser,
    meetingId,
    setMeetingId,
    handleStopAudio,
    endedCallTune,
    startCallTune,
  } = useClassRoom();
  const { userInfo, socket, startedMeeting } = useContentSetting();
  const [micOn, setMicOn] = useState(true);
  const [joined, setJoined] = useState(null);
  const [isSharedScreen, setIsSharedScreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const { setBoxType, boxType } = props;

  const handle_update_media = (status_type, value) => {
    socket.emit("changed_media", {
      from: userInfo._id,
      meeting_id: meetingId,
      status_type: status_type,
      value: value,
    });
  };

  const {} = useParticipant(userInfo._id, {
    onMediaStatusChanged: (data) => {
      if (data.kind == "audio") {
        setMicOn(data.newStatus);
        handle_update_media("muted", !data.newStatus);
      }
    },
  });

  const {
    join,
    participants,
    end,
    toggleMic,
    toggleScreenShare,
    muteMic,
    enableScreenShare,
  } = useMeeting({
    onMeetingJoined: (e) => {
      setJoined("JOINED");
    },
    onPresenterChanged: (presenter_id) => {
      if (presenter_id == userInfo._id) {
        handle_update_media("screen_share", true);
        setIsSharedScreen(true);
      }
    },
    onMeetingLeft: () => {
      setMeetingId(null);
    },
    onMeetingEnded: () => {
      setMeetingId();
    },
  });

  const handleStopCall = () => {
    end();
    setMeetingId(null);
    handleStopAudio();
    endedCallTune.play();
  };

  const joinMeeting = () => {
    setJoined("JOINING");
    join();
    setTimeout(async () => {
      const result = await message_by_meeting_api(meetingId);
      let status = result?.chat_message?.call_status;
      if (status == "ringing" || status == "calling") {
        let event = "call:no_answered";
        if (status == "calling") {
          event = "call:unavailable";
        }
        socket.emit(event, {
          to: callUser,
          from: userInfo._id,
          meeting_id: meetingId,
        });
        handleStopCall();
      }
    }, 30000);
  };

  const acceptCall = () => {
    setIsIncomingCall(false);
    setJoined("JOINING");
    join();
    socket.emit("call:accepted", {
      to: callUser,
      from: userInfo._id,
      meeting_id: meetingId,
    });
  };
  const handletoggleMic = () => {
    toggleMic();
  };

  const handletoggleShare = () => {
    toggleScreenShare();
    if (isSharedScreen) {
      setIsSharedScreen(!isSharedScreen);
      handle_update_media("screen_share", false);
    }
  };

  const handleEndCall = () => {
    socket.emit("call:ended", {
      to: callUser,
      from: userInfo._id,
      meeting_id: meetingId,
    });
    handleStopCall();
  };

  const handleDeclineCall = () => {
    socket.emit("call:declined", {
      to: callUser,
      from: userInfo._id,
      meeting_id: meetingId,
    });
    handleStopCall();
  };

  const handleCancelCall = () => {
    socket.emit("call:canceled", {
      to: callUser,
      from: userInfo._id,
      meeting_id: meetingId,
    });
    handleStopCall();
  };

  const handleCallDeclined = async () => {
    handleStopCall();
  };

  const handleCallEnded = async () => {
    handleStopCall();
  };

  const handletoggleAudio = () => {
    if (!startCallTune.paused) {
      setIsPlaying(false);
      startCallTune.pause();
    } else {
      setIsPlaying(true);
      startCallTune.play();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (autoJoin) {
        joinMeeting();
      }
    }, 100);

    socket.on("call:declined", handleCallDeclined);
    socket.on("call:ended", handleCallEnded);
  }, []);

  useEffect(() => {
    if (joined && startedMeeting) {
      setTimeout(() => {
        let find_participant = startedMeeting.participants.find(
          (participant) => participant.member == userInfo._id
        );
        if (find_participant) {
          if (find_participant.muted) {
            muteMic();
          }
          if (find_participant.screen_share) {
            enableScreenShare();
          }
        }
      }, 500);
    }
  }, [joined]);

  let find_student = [...participants.keys()].find(
    (participantId) => participantId !== userInfo._id
  );

  return (
    <>
      {joined && joined == "JOINED" ? (
        <>
          {/* <Controls /> */}
          {find_student ? (
            <ParticipantView
              participantId={find_student}
              key={find_student}
              setBoxType={setBoxType}
              boxType={boxType}
            />
          ) : (
            <div className="image-box">
              <img src={logo} alt="" />
            </div>
          )}
        </>
      ) : (
        <div className="image-box">
          <img src={logo} alt="" />
        </div>
      )}
      {boxType !== "CIRCLE" && joined && joined == "JOINED" && (
        <div className="icon-box">
          {isIncomingCall ? (
            <Tooltip title={`Join Class`}>
              <div className="success-call-box" onClick={() => acceptCall()}>
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`subway:call-1`}
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title={`${micOn ? "Mute" : "Unmute"}`}>
              <div
                className="success-call-box"
                onClick={() => handletoggleMic()}
              >
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`${micOn ? "bi:mic-fill" : "vaadin:mute"}`}
                />
              </div>
            </Tooltip>
          )}
          {!isCallAccepted ? (
            <Tooltip title={`${isPlaying ? "Off" : "On"}`}>
              <div className="success-call-box" onClick={handletoggleAudio}>
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`${
                    isPlaying ? "fa-solid:volume-up" : "fa-solid:volume-mute"
                  }`}
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              title={`${isSharedScreen ? "Stop Sharing" : "Share Screen"}`}
            >
              <div
                className="success-call-box"
                onClick={() => handletoggleShare()}
              >
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`${
                    isSharedScreen
                      ? "fluent:share-screen-stop-28-filled"
                      : "fluent:share-screen-start-28-filled"
                  }`}
                />
              </div>
            </Tooltip>
          )}
          <Tooltip
            title={`${
              isIncomingCall
                ? "Decline Call"
                : isCallAccepted
                ? "Drop Call"
                : "Cancel Call"
            }`}
            onClick={() => {
              if (isIncomingCall) {
                handleDeclineCall();
              } else if (isCallAccepted) {
                handleEndCall();
              } else {
                handleCancelCall();
              }
            }}
          >
            <div className="success-call-box error">
              <Icon
                fontSize="18"
                className="start-call-icon"
                icon="subway:call-3"
              />
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
}
