import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import { Link, useParams } from "react-router-dom";
import {
  delete_todo_list_api,
  todo_list_by_user_api,
} from "../../DAL/ToDoList/ToDoList";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  { id: "title", label: "Title" },
  { id: "description", label: "Description" },
  {
    id: "priority",
    label: "Priority",
    renderData: (row) => {
      return (
        <span className={`todo-priority ${row.priority}`}>{row.priority}</span>
      );
    },
  },
  { id: "action", label: "Action", type: "action" },
];

export default function ToDoListsByUser({ user_type }) {
  const { enqueueSnackbar } = useSnackbar();
  const { user_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [toDoLists, setToDoLists] = useState([]);
  const [actionInfo, setActionInfo] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  const get_todo_lists_list = async () => {
    let postData = {
      user_type,
      action_id: user_id,
    };
    const result = await todo_list_by_user_api(postData);
    if (result.code === 200) {
      setToDoLists(result.todo_lists);
      setActionInfo(result.user_info);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_todo_list_api(selectedObject._id);
    if (result.code === 200) {
      const data = toDoLists.filter(
        (todo_list) => todo_list._id !== selectedObject._id
      );
      setToDoLists(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_todo_lists_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="back-button-title mb-3">
                <div class="back-button-box">
                  <Link
                    to={`${
                      user_type === "student"
                        ? "/students"
                        : user_type === "associate"
                        ? "/associates"
                        : "/teachers"
                    }`}
                  >
                    <i className="fas fa-arrow-left"></i>
                  </Link>
                </div>
                <div className="table-title">
                  {user_type === "student"
                    ? actionInfo.name +
                      " (" +
                      actionInfo.roll_number_string +
                      ")"
                    : actionInfo.name + " (" + actionInfo.email + ")"}
                </div>
              </div>
              <div className="light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>To Do List</h2>
                </div>
              </div>
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={toDoLists}
                MENU_OPTIONS={MENU_OPTIONS}
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this personal note?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
