import React, { useCallback, useState } from "react";
import { useContentSetting } from "../Hooks/ContentSetting";
import peer from "../service/peer";
import ReactPlayer from "react-player";
import { useEffect } from "react";

export default function Caller2() {
  const { socket } = useContentSetting();
  const [myStream, setMyStream] = useState();
  const [selectedStudent, setSelectedStudent] = useState(
    "651446e8b73bca990adde64a"
  );

  const handleStartCall = useCallback(async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    const offer = await peer.getOffer();
    socket.emit("user:call", { to: selectedStudent, offer });
    setMyStream(stream);
  }, [selectedStudent, socket]);

  const sendStreams = useCallback(() => {
    for (const track of myStream.getTracks()) {
      peer.peer.addTrack(track, myStream);
    }
  }, [myStream]);

  const handleCallAccepted = useCallback(
    ({ from, ans }) => {
      peer.setLocalDescription(ans);
      console.log("Call Accepted!");
      sendStreams();
    },
    [sendStreams]
  );

  useEffect(() => {
    socket.on("call:accepted", handleCallAccepted);

    return () => {
      socket.off("call:accepted", handleCallAccepted);
    };
  }, [handleCallAccepted]);

  return (
    <div>
      {!myStream && <button onClick={handleStartCall}>Call</button>}
      {myStream && <button onClick={sendStreams}>Send Stream</button>}
      {myStream && (
        <>
          <h1>My Stream</h1>
          <ReactPlayer
            playing
            muted
            height="100px"
            width="200px"
            url={myStream}
          />
        </>
      )}
    </div>
  );
}
